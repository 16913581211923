var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from "react-i18next";
import { useJesuisJecherche } from '../../../../application/Context/JesuisJechercheContext';
export var MessageForm = function (_a) {
    var projectId = _a.projectId;
    var getCurrentUserId = useJesuisJecherche().getCurrentUserId;
    var t = useTranslation(['jesuis-jecherche']).t;
    var _b = useJesuisJecherche(), createComment = _b.createComment, handleComment = _b.handleComment, enteredComment = _b.enteredComment, updateComment = _b.updateComment, commentEditing = _b.commentEditing, commentIdToEdit = _b.commentIdToEdit;
    if (getCurrentUserId === 'None') {
        // Handle the case where the user ID is null
        return (_jsx("div", __assign({ className: 'response-section', style: { "padding": 10 } }, { children: _jsx("p", __assign({ style: { "fontSize": 16, "textAlign": "center", "margin": 0 } }, { children: t("Please log in to comment") })) })));
    }
    return (_jsx(React.Fragment, { children: _jsxs("div", __assign({ className: 'response-section' }, { children: [_jsx("textarea", { className: 'textarea', name: "", value: enteredComment, placeholder: t("Réponse"), onChange: handleComment }), _jsx("div", { className: 'blue-line' }), _jsx("div", __assign({ className: 'response-section-controllers' }, { children: _jsxs("button", __assign({ className: "btn btn-primary compact", onClick: function (e) {
                            return commentEditing ? updateComment(e, projectId, commentIdToEdit) : createComment(e, projectId);
                        } }, { children: [" ", t("Send")] })) }))] })) }));
};
