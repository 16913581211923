var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var TagContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\ndisplay:flex;\nflex-wrap:wrap;"], ["\ndisplay:flex;\nflex-wrap:wrap;"])));
export var Tag = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\nfont-size:0.875rem;\npadding:10px 16px;\ncolor: ", ";\nbackground-color:", ";\nfont-weight:500;\nmargin-top:.5rem;\nwidth:fit-content;\n"], ["\nfont-size:0.875rem;\npadding:10px 16px;\ncolor: ", ";\nbackground-color:", ";\nfont-weight:500;\nmargin-top:.5rem;\nwidth:fit-content;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.bluePiv;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.bluePale;
});
export var Category = styled.ul(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\nlist-style:none;\npadding:0;\nfont-family:'Open Sans',sans-serif;\nfont-size:14px;\ncolor: ", "\n"], ["\nlist-style:none;\npadding:0;\nfont-family:'Open Sans',sans-serif;\nfont-size:14px;\ncolor: ", "\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.blueFonce;
});
export var CompanyDetails = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\nborder-bottom: 1px solid ", ";\ndisplay:flex;\ngap:1.5rem;\npadding: 0 0 16px;\nmargin:30px 0;\n"], ["\nborder-bottom: 1px solid ", ";\ndisplay:flex;\ngap:1.5rem;\npadding: 0 0 16px;\nmargin:30px 0;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.grisClair;
});
export var Mrc = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\ndisplay:flex;\nalign-items:center;\nfont-family:'Open Sans',sans-serif;\nfont-size:14px;\ncolor : ", ";\ngap:8px;\nmargin-bottom:1rem;\np{\n    margin:0;\n}\n"], ["\ndisplay:flex;\nalign-items:center;\nfont-family:'Open Sans',sans-serif;\nfont-size:14px;\ncolor : ", ";\ngap:8px;\nmargin-bottom:1rem;\np{\n    margin:0;\n}\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.blueFonce;
});
export var CompanyTitle = styled.h4(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\nfont-size:1.18rem;\nfont-family:'Open Sans',sans-serif;\ncolor : ", ";\nfont-weight:bold;\nmargin-bottom:16px;\n"], ["\nfont-size:1.18rem;\nfont-family:'Open Sans',sans-serif;\ncolor : ", ";\nfont-weight:bold;\nmargin-bottom:16px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.blueFonce;
});
export var Description = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\ndisplay:flex;\nflex-direction:column;\n"], ["\ndisplay:flex;\nflex-direction:column;\n"])));
export var Text = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\nfont-family:'Open Sans',sans-serif;\nfont-size:14px;\ncolor : ", ";\nflex: 1 1 auto;\n& p{\n    font-family:'Open Sans',sans-serif;\n}\n\n& div{\n    display:flex;\n    gap:8px;\n    margin-bottom:16px;\n}\n\n"], ["\nfont-family:'Open Sans',sans-serif;\nfont-size:14px;\ncolor : ", ";\nflex: 1 1 auto;\n& p{\n    font-family:'Open Sans',sans-serif;\n}\n\n& div{\n    display:flex;\n    gap:8px;\n    margin-bottom:16px;\n}\n\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.blueFonce;
});
export var Title = styled.h4(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n\nfont-size:1.5rem;\nfont-family:'Roboto',sans-serif;\nfont-weight:bold;\nword-break:break-word;\ncursor:pointer;\n\n & a {\n    color : ", ";\n\n    &:hover {\n       text-decoration: underline;\n    }\n }\n"], ["\n\nfont-size:1.5rem;\nfont-family:'Roboto',sans-serif;\nfont-weight:bold;\nword-break:break-word;\ncursor:pointer;\n\n & a {\n    color : ", ";\n\n    &:hover {\n       text-decoration: underline;\n    }\n }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.blueFonce;
});
export var Subtitle = styled.h4(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 1.2rem;\n  font-family: \"Roboto\", sans-serif;\n  font-weight: bold;\n"], ["\n  color: ", ";\n  font-size: 1.2rem;\n  font-family: \"Roboto\", sans-serif;\n  font-weight: bold;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.blueFonce;
});
export var IconContainerBig = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n\nbackground-color: ", ";\nborder:1px solid  ", ";\ndisplay:flex;\nalign-items:center;\njustify-content:center;\nheight: 34px;\nwidth: 34px;\nposition:absolute;\ntop: 10px;\nright: 10px;\n\n> img{\n    padding:0;\n    width:100%;\n    height:100%;\n    object-fit: contain;\n}\n\n"], ["\n\nbackground-color: ", ";\nborder:1px solid  ", ";\ndisplay:flex;\nalign-items:center;\njustify-content:center;\nheight: 34px;\nwidth: 34px;\nposition:absolute;\ntop: 10px;\nright: 10px;\n\n> img{\n    padding:0;\n    width:100%;\n    height:100%;\n    object-fit: contain;\n}\n\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.bluePiv;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.bluePale;
});
export var ImageContainer = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\nborder:1px solid  ", ";\nflex: 0 0 ", ";\nmargin-bottom:", "px;\naspect-ratio: 16/11;\nposition:relative;\ndisplay:flex;\nalign-items:center;\njustify-content:center;\n> img{\n    width:100%;\n    height:100%;\n    object-fit: contain;\n }\n\n"], ["\nborder:1px solid  ", ";\nflex: 0 0 ", ";\nmargin-bottom:", "px;\naspect-ratio: 16/11;\nposition:relative;\ndisplay:flex;\nalign-items:center;\njustify-content:center;\n> img{\n    width:100%;\n    height:100%;\n    object-fit: contain;\n }\n\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.bluePale;
}, function (props) { return props.width + "rem"; }, function (props) { return props.marginBottom; });
export var CompanyImageContainer = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\nborder:1px solid  ", ";\nflex: 0 0 ", ";\nmargin-bottom:", "px;\naspect-ratio: 16/11;\nposition:relative;\ndisplay:flex;\nalign-items:center;\njustify-content:center;\n> img{\n    width:100%;\n    height:100%;\n    object-fit: contain;\n    padding:16px;\n }\n\n"], ["\nborder:1px solid  ", ";\nflex: 0 0 ", ";\nmargin-bottom:", "px;\naspect-ratio: 16/11;\nposition:relative;\ndisplay:flex;\nalign-items:center;\njustify-content:center;\n> img{\n    width:100%;\n    height:100%;\n    object-fit: contain;\n    padding:16px;\n }\n\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.bluePale;
}, function (props) { return props.width + "rem"; }, function (props) { return props.marginBottom; });
export var DateContainer = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\nfont-family:'Open Sans',sans-serif;\nfont-size:14px;\n"], ["\nfont-family:'Open Sans',sans-serif;\nfont-size:14px;\n"])));
export var Action = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\ndisplay:flex;\nalign-items:center;\ncolor:", ";\nmargin:16px 0;\ngap:5px;\ncursor:pointer;\n"], ["\ndisplay:flex;\nalign-items:center;\ncolor:", ";\nmargin:16px 0;\ngap:5px;\ncursor:pointer;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.bluePiv;
});
//Events
export var EventsContainer = styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\ndisplay:flex;\ngap:24px;\npadding:0 0 30px 0;\nmargin:30px 0;\nborder-bottom: 1px solid ", ";\n"], ["\ndisplay:flex;\ngap:24px;\npadding:0 0 30px 0;\nmargin:30px 0;\nborder-bottom: 1px solid ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.gris;
});
export var EventsImage = styled.div(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\ndisplay:flex;\nalign-items:center;\njustify-content:center;\naspect-ratio: 16/11;\nborder: 1px solid ", ";\n\n    img {\n    width:100%;\n    height:100%;\n    object-fit: contain;\n    }\n\n"], ["\ndisplay:flex;\nalign-items:center;\njustify-content:center;\naspect-ratio: 16/11;\nborder: 1px solid ", ";\n\n    img {\n    width:100%;\n    height:100%;\n    object-fit: contain;\n    }\n\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.grisClair;
});
export var EventsTitle = styled.h4(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\nfont-family:'Open Sans',sans-serif;\nfont-weight:bold;\nfont-size: 1.125rem;\nmargin-bottom:16px;\n \n& a{\n    color:", ";\n\n    &:hover {\n       text-decoration: underline;\n    }\n}\n\n"], ["\nfont-family:'Open Sans',sans-serif;\nfont-weight:bold;\nfont-size: 1.125rem;\nmargin-bottom:16px;\n \n& a{\n    color:", ";\n\n    &:hover {\n       text-decoration: underline;\n    }\n}\n\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.blueFonce;
});
// News
export var NewsContainer = styled.div(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\ndisplay:flex;\nalign-items: flex-start;\ngap:24px;\nmargin:30px 0 0 0;\nborder-bottom: 1px solid ", ";\n\n& img {\n  flex: 0 0 auto;\n  width: 100%;\n  height:100%;\n}\n"], ["\ndisplay:flex;\nalign-items: flex-start;\ngap:24px;\nmargin:30px 0 0 0;\nborder-bottom: 1px solid ", ";\n\n& img {\n  flex: 0 0 auto;\n  width: 100%;\n  height:100%;\n}\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.gris;
});
export var NewsTitle = styled.h4(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\ncolor:", ";\nfont-family:'Roboto',sans-serif;\nfont-weight:bold;\nfont-size: 1.125rem;\n \n& a{\n    color:", ";\n\n    &:hover {\n       text-decoration: underline;\n    }\n}\n"], ["\ncolor:", ";\nfont-family:'Roboto',sans-serif;\nfont-weight:bold;\nfont-size: 1.125rem;\n \n& a{\n    color:", ";\n\n    &:hover {\n       text-decoration: underline;\n    }\n}\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.bluePiv;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.blueFonce;
});
export var Container = styled.div(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  border-bottom: 1px solid ", ";\n  margin: 30px 0;\n"], ["\n  border-bottom: 1px solid ", ";\n  margin: 30px 0;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.grisClair;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21;
