var Theme = {
    colors: {
        // Blue 
        bluePale: "#dae6f0",
        blueClair: "#4a98d9",
        blue: "#1479cc",
        bluePiv: "#095797",
        blueMoyen: "#19406c",
        blueFonce: "#223654",
        // Yellow/Jaunes 
        jaunePale: "#f8e69a",
        jaune: "#e0ad03",
        jauneFonce: "#ad781c",
        // Green/Verts 
        vertPale: "#dcf0bb",
        vert: "#4f813d",
        vertFonce: "#2c4024",
        // Gray/Gris 
        grisPale: "#f1f1f2",
        grisClair: "#c5cad2",
        gris: "#8893a2",
        grisMoyen: " #6d778a",
        grisFonce: "#4e5662",
        // Red/Rouges 
        orangePale: "#de724d",
        rosePale: "#ffdbd6",
        rose: "#e58271",
        rouge: "#cb381f",
        rougeFonce: "#692519",
        // Alerts 
        alertRed: "#cb381c",
        alertYellow: "#fce58f",
        alertBlue: "#d9e6f0",
        alertPink: "#edbab1",
    },
};
export default Theme;
