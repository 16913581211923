var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Suspense, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { JesuisJechercheProvider, useJesuisJecherche } from "./Context/JesuisJechercheContext";
import { SearchBar } from "../components/react-components/jesuis-jecherche-components/SearchBar";
import { ThemeProvider } from "styled-components";
import Theme from "../components/react-components/Styles/Theme";
import { I18nextProvider, useTranslation } from "react-i18next";
import i18next from "i18next";
import i18n from "../i18n/i18n";
import { PageLoading } from "../components/react-components/shared-components/Loading/PageLoading";
// eslint-disable-next-line no-unused-vars
import ProjectsList from "../components/react-components/jesuis-jecherche-components/Projects/ProjectsList";
import { Title } from "../components/react-components/shared-components/Title/Title";
import { InfoBar } from "../components/react-components/jesuis-jecherche-components/InfoBar";
// eslint-disable-next-line no-unused-vars
import { Container } from "../components/react-components/Styles/SharedStyledComponents";
import { ContentContainer } from "../components/react-components/shared-components/Ui/Grid/Grid";
// eslint-disable-next-line no-unused-vars
var JesuisJecherche = function () {
    var _a;
    // eslint-disable-next-line no-unused-vars
    var projectsIsLoading = useJesuisJecherche().projectsIsLoading;
    var getTitle = useJesuisJecherche().getTitle;
    var langCode = ((_a = document.getElementById("language-code")) === null || _a === void 0 ? void 0 : _a.value) || "en";
    var title = getTitle(langCode);
    var t = useTranslation(['common']).t;
    var capitalizedTitle = t(title.charAt(0).toUpperCase() + title.slice(1));
    useEffect(function () {
        // @ts-ignore
        if ((langCode === null || langCode === void 0 ? void 0 : langCode.length) === 2) {
            i18next.changeLanguage(langCode);
        }
    }, [langCode]);
    if (window.innerWidth < 769) {
        if (document.getElementById("#jesuisjecherche")) { //verify the id of the html document for je suis je cherche
            document.querySelectorAll("body")[0].style.overflow = "hidden";
        }
    }
    return (_jsx(Suspense, __assign({ fallback: _jsx(PageLoading, {}) }, { children: _jsx(I18nextProvider, __assign({ i18n: i18n }, { children: _jsx(JesuisJechercheProvider, { children: _jsx(ThemeProvider, __assign({ theme: Theme }, { children: _jsx(React.Fragment, { children: _jsx("div", __assign({ className: "container" }, { children: _jsxs("div", __assign({ className: "pr-row" }, { children: [_jsx("div", __assign({ className: "pr-row-left", style: { "padding": 10, "border": 0, "position": "static" } }, { children: _jsx("div", __assign({ className: "pr-tabs-content" }, { children: _jsx("div", __assign({ className: "pr-tab-cont active ps-3" }, { children: _jsx(SearchBar, {}) })) })) })), _jsxs(ContentContainer, { children: [_jsx("div", { children: _jsx(Title, { title: capitalizedTitle }) }), _jsx(InfoBar, {}), _jsx(ProjectsList, {})] })] })) })) }) })) }) })) })));
};
var domNode = document.querySelector("#jesuisjecherche");
if (domNode) {
    var root = createRoot(domNode);
    root.render(_jsx(JesuisJechercheProvider, { children: _jsx(JesuisJecherche, {}) }));
}
