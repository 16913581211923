var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
// eslint-disable-next-line no-unused-vars
import { useTranslation } from "react-i18next";
import { MessageHeader } from './MessageHeader';
import { MessageContent } from './MessageContent';
export var Message = function (_a) {
    var id = _a.id, comment = _a.comment, author = _a.author, date = _a.date, projectId = _a.projectId;
    return (_jsx(React.Fragment, { children: _jsxs("div", __assign({ className: "post-comments-unit" }, { children: [_jsx(MessageHeader, { id: id, projectId: projectId, author: author, date: date }), _jsx(MessageContent, { message: comment })] })) }));
};
