var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useRef } from 'react';
import { ProjectAvatar } from './ProjectAvatar';
import ReactTimeAgo from 'react-time-ago';
import useOutsideClick from '../../shared-components/useOutsideClick';
export var ProjectHeader = function (_a) {
    var author = _a.author, date = _a.date;
    // eslint-disable-next-line no-unused-vars
    var _b = __read(React.useState(''), 2), controllersTooltip = _b[0], setControllersTooltip = _b[1];
    var tooltipRef = useRef(null);
    useOutsideClick(tooltipRef, function () {
        setControllersTooltip('');
    });
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "post-header" }, { children: _jsxs("div", __assign({ className: "post-header-unit-top" }, { children: [_jsx(ProjectAvatar, { author: author }), _jsx("div", __assign({ className: "post-header-top" }, { children: _jsx("p", __assign({ className: "auth-name" }, { children: author })) }))] })) })), _jsx("p", __assign({ className: "time-ago" }, { children: _jsx(ReactTimeAgo, { date: new Date(date), locale: "en-us", timeStyle: "twitter" }) }))] }));
};
