var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useContext, useState } from "react";
import Cookies from 'js-cookie';
var JesuisJechercheContext = createContext();
export var JesuisJechercheProvider = function (_a) {
    var _b;
    var children = _a.children;
    var langCode = ((_b = document.getElementById("language-code")) === null || _b === void 0 ? void 0 : _b.value) || "en";
    var getCurrentUserId = document.getElementById("current-auth-user-id").value;
    var currentUserFirstName = document.getElementById("current-auth-user-first-name").value;
    var currentUserLastName = document.getElementById("current-auth-user-last-name").value;
    var currentUserCompanyName = document.getElementById("current-auth-user-company-name").value;
    var currentUserCompanyId = document.getElementById("current-auth-user-company-id").value;
    // State for projects
    var _c = __read(useState([]), 2), projects = _c[0], setProjects = _c[1];
    var _d = __read(useState(true), 2), projectsIsLoading = _d[0], setProjectsIsLoading = _d[1];
    var _e = __read(React.useState(0), 2), page = _e[0], setPage = _e[1];
    var _f = __read(useState(0), 2), pageCount = _f[0], setPageCount = _f[1];
    var _g = __read(useState(""), 2), nextProjects = _g[0], setNextProjects = _g[1];
    var _h = __read(useState("defaultView"), 2), view = _h[0], setView = _h[1];
    //state for infinite scroll
    var _j = __read(useState(false), 2), newProjectIsLoading = _j[0], setNewProjectIsLoading = _j[1];
    var _k = __read(useState(false), 2), hasMoreData = _k[0], setHasMoreData = _k[1];
    //elements/ items Count used in info bar
    var _l = __read(React.useState(0), 2), count = _l[0], setCount = _l[1];
    //Search State for search bar
    var _m = __read(useState(""), 2), searchInput = _m[0], setSearchInput = _m[1];
    var _o = __read(useState(""), 2), searchedTerm = _o[0], setSearchedTerm = _o[1];
    //States for comments
    var _p = __read(useState({}), 2), comments = _p[0], setComments = _p[1];
    var _q = __read(useState(false), 2), commentEditing = _q[0], setCommentEditing = _q[1];
    var _r = __read(useState(""), 2), commentIdToEdit = _r[0], setCommentIdToEdit = _r[1];
    var _s = __read(useState(""), 2), enteredComment = _s[0], setEnteredComment = _s[1];
    var _t = __read(React.useState(null), 2), commentsToShow = _t[0], setCommentsToShow = _t[1];
    // Modal State
    var _u = __read(useState(false), 2), confirmationModalIsOpen = _u[0], setConfirmationModalIsOpen = _u[1];
    // Fetch Projects
    // Define an asynchronous function fetchProjects
    var fetchProjects = function (queryString, page) {
        if (queryString === void 0) { queryString = ""; }
        if (page === void 0) { page = 1; }
        return __awaiter(void 0, void 0, void 0, function () {
            // Define an asynchronous function postData to send a POST request with the query
            function postData(url, data) {
                if (url === void 0) { url = "/".concat(langCode, "/api/search/"); }
                if (data === void 0) { data = {}; }
                return __awaiter(this, void 0, void 0, function () {
                    var response;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, fetch(url, {
                                    method: "POST",
                                    mode: "cors",
                                    cache: "no-cache",
                                    credentials: "same-origin",
                                    headers: {
                                        "X-CSRFToken": CSRF_TOKEN,
                                        "Content-Type": "application/json",
                                    },
                                    redirect: "follow",
                                    referrerPolicy: "no-referrer",
                                    body: JSON.stringify(data),
                                })];
                            case 1:
                                response = _a.sent();
                                return [4 /*yield*/, response.json()];
                            case 2: return [2 /*return*/, _a.sent()];
                        }
                    });
                });
            }
            var CSRF_TOKEN, query;
            return __generator(this, function (_a) {
                // Set a loading state to true, indicating that projects are being fetched
                setProjectsIsLoading(true);
                CSRF_TOKEN = Cookies.get("csrftoken");
                query = {
                    query: queryString,
                    page: page,
                    filters: [{
                            Project: {
                                conditions: [{
                                        condition: "where",
                                        field: "project_type",
                                        operator: "is",
                                        value: "jesuis_jecherche",
                                    }, {
                                        "condition": "where",
                                        "field": "visible_jesuis_jecherche",
                                        "operator": "is",
                                        "value": true
                                    }]
                            }
                        }]
                };
                // Call postData with the specified URL and query
                postData("/".concat(langCode, "/api/search/"), query)
                    .then(function (data) {
                    // Process the response data
                    var newProjects = data.results.map(function (data) { return data.result; });
                    setPageCount(Math.ceil(data.count / 10));
                    data.next ? setHasMoreData(true) : setHasMoreData(false);
                    setNextProjects(data.next);
                    setProjects(newProjects);
                    setCount(data.count);
                    // Set loading state to false, indicating that projects have been fetched
                    setProjectsIsLoading(false);
                });
                return [2 /*return*/];
            });
        });
    };
    //loadNewProjects
    var loadNewProjects = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (newProjectIsLoading || !hasMoreData) {
                return [2 /*return*/];
            }
            setNewProjectIsLoading(true);
            fetch("".concat(nextProjects))
                .then(function (response) { return response.json(); })
                .then(function (data) {
                data.next ? setHasMoreData(true) : setHasMoreData(false);
                console.log("projects", projects);
                console.log("data.results ", data.results);
                setProjects(__spreadArray(__spreadArray([], __read(projects), false), __read(data.results), false));
            }).catch(function (e) { return console.log(e); });
            setNewProjectIsLoading(false);
            return [2 /*return*/];
        });
    }); };
    //get title
    var getTitle = function (langCode) {
        return langCode === 'fr' ? "Je cherche/J'offre" : 'I am looking for/I am Offering';
    };
    //handle key down for search bar
    var fetchComments = function (projectId, page) {
        if (page === void 0) { page = 1; }
        return __awaiter(void 0, void 0, void 0, function () {
            // Define an asynchronous function postData to send a POST request with the query
            function postData(url, data) {
                if (url === void 0) { url = "/".concat(langCode, "/api/search/"); }
                if (data === void 0) { data = {}; }
                return __awaiter(this, void 0, void 0, function () {
                    var response;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, fetch(url, {
                                    method: "POST",
                                    mode: "cors",
                                    cache: "no-cache",
                                    credentials: "same-origin",
                                    headers: {
                                        "X-CSRFToken": CSRF_TOKEN,
                                        "Content-Type": "application/json",
                                    },
                                    redirect: "follow",
                                    referrerPolicy: "no-referrer",
                                    body: JSON.stringify(data),
                                })];
                            case 1:
                                response = _a.sent();
                                return [2 /*return*/, response.json()];
                        }
                    });
                });
            }
            var CSRF_TOKEN, query;
            return __generator(this, function (_a) {
                CSRF_TOKEN = Cookies.get("csrftoken");
                query = {
                    query: "",
                    page: page,
                    filters: [{
                            Comment: {
                                conditions: [{
                                        condition: "where",
                                        field: "object_id",
                                        operator: "is",
                                        value: projectId,
                                    }],
                            },
                        }],
                };
                // Call postData with the specified URL and query
                postData("/".concat(langCode, "/api/search/"), query)
                    .then(function (data) {
                    // Process the response data
                    var newComments = data.results.map(function (data) { return data.result; });
                    setComments(function (oldComments) {
                        var _a;
                        return (__assign(__assign({}, oldComments), (_a = {}, _a[projectId] = newComments, _a)));
                    });
                });
                return [2 /*return*/];
            });
        });
    };
    var handleKeyDown = function (event) {
        if (event.key === "Enter") {
            setPage(0);
            setSearchedTerm(searchInput);
        }
    };
    // search projects from the lists for search bar
    var searchProjects = function () {
        setPage(0);
        setSearchedTerm(searchInput);
    };
    var handleSearchInput = function (e) {
        setSearchInput(e.target.value);
    };
    // Create Comment
    var CSRF_TOKEN = Cookies.get("csrftoken");
    var createComment = function (e, projectId) { return __awaiter(void 0, void 0, void 0, function () {
        var capitalizeFirstLetter;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    capitalizeFirstLetter = function (str) {
                        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
                    };
                    if (!enteredComment) return [3 /*break*/, 2];
                    return [4 /*yield*/, fetch("/".concat(langCode, "/api/comments/create-comment/"), {
                            method: "POST",
                            mode: "cors",
                            cache: "no-cache",
                            credentials: "same-origin",
                            headers: {
                                'X-CSRFToken': CSRF_TOKEN,
                                "Content-Type": "application/json",
                            },
                            redirect: "follow",
                            referrerPolicy: "no-referrer",
                            body: JSON.stringify({
                                "content": enteredComment,
                                "object_id": projectId,
                                "author": parseInt(getCurrentUserId),
                                "username": "".concat(capitalizeFirstLetter(currentUserFirstName), " ").concat(capitalizeFirstLetter(currentUserLastName)),
                                "company": {
                                    "id": currentUserCompanyId,
                                    "name": currentUserCompanyName,
                                }
                            }),
                        })
                            .then(function (response) { return response.json(); })
                            .then(function (comment) {
                            setComments(function (oldComments) {
                                var _a;
                                return (__assign(__assign({}, oldComments), (_a = {}, _a[projectId] = __spreadArray(__spreadArray([], __read(oldComments[projectId]), false), [comment], false), _a)));
                            });
                        })];
                case 1:
                    _a.sent();
                    resetCommentInputs();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    // Edit Comment
    var editComment = function (e, projectId, commentId) { return __awaiter(void 0, void 0, void 0, function () {
        var projectComments, existingComment;
        return __generator(this, function (_a) {
            projectComments = comments[projectId];
            existingComment = projectComments.filter(function (c) { return c.id === commentId; })[0];
            setCommentEditing(true);
            setCommentIdToEdit(commentId);
            setEnteredComment(existingComment.content);
            return [2 /*return*/];
        });
    }); };
    var updateComment = function (e, projectId, commentId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!enteredComment) return [3 /*break*/, 2];
                    return [4 /*yield*/, fetch("/".concat(langCode, "/api/comments/update-comment/").concat(commentId, "/"), {
                            method: "PUT",
                            mode: "cors",
                            cache: "no-cache",
                            credentials: "same-origin",
                            headers: {
                                'X-CSRFToken': CSRF_TOKEN,
                                "Content-Type": "application/json",
                            },
                            redirect: "follow",
                            referrerPolicy: "no-referrer",
                            body: JSON.stringify({ "content": enteredComment }),
                        })
                            .then(function () {
                            var updatedComments = comments[projectId].map(function (commentObj) {
                                var _a;
                                if (commentObj.object_id === projectId) {
                                    return __assign(__assign({}, commentObj), { comments: ((_a = commentObj.comments) !== null && _a !== void 0 ? _a : []).map(function (comment) {
                                            return comment.id === commentId ? __assign(__assign({}, comment), { content: enteredComment }) : comment;
                                        }) });
                                }
                                return commentObj;
                            });
                            setComments(updatedComments);
                            fetchComments(projectId);
                        })];
                case 1:
                    _a.sent();
                    resetCommentInputs();
                    setCommentEditing(false);
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    // Delete Comment
    var deleteComment = function (e, projectId, commentId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch("/".concat(langCode, "/api/comments/delete-comment/").concat(commentId, "/"), {
                        method: "DELETE",
                        mode: "cors",
                        cache: "no-cache",
                        credentials: "same-origin",
                        headers: {
                            'X-CSRFToken': CSRF_TOKEN,
                            "Content-Type": "application/json",
                        },
                        redirect: "follow",
                        referrerPolicy: "no-referrer",
                    })
                        .then(function () {
                        var updatedComments = comments[projectId].map(function (commentObj) {
                            if (commentObj.project_id === projectId) {
                                var newResponseNbr = commentObj.responseNbr - 1;
                                var updatedComments_1 = commentObj.comments.filter(function (comment) { return comment.id !== commentId; });
                                return __assign(__assign({}, commentObj), { comments: updatedComments_1, responseNbr: newResponseNbr });
                            }
                            return commentObj;
                        });
                        setComments(updatedComments);
                        fetchComments(projectId);
                        fetchProjects();
                    })];
                case 1:
                    _a.sent();
                    setConfirmationModalIsOpen(false);
                    return [2 /*return*/];
            }
        });
    }); };
    // Reset Comment Inputs
    var resetCommentInputs = function () {
        setEnteredComment("");
        setCommentIdToEdit("");
    };
    // Get Comments
    // const showComments = async (projectId) => {
    //     const response = await fetch(`/${langCode}/api/projects/${projectId}/comments/?limit=5000`);
    //     const data = await response.json();
    //     const newComments = {project_id: projectId, comments: data.results, responseNbr: data.results.length};
    //
    //     const commentId = comments.findIndex(obj => obj.project_id === projectId);
    //
    //     // when there are comments
    //     if (commentId !== -1) {
    //         const updatedComments = comments.map((comment) => {
    //             if (comment.project_id === projectId) {
    //                 return newComments;
    //             }
    //             return comment;
    //         });
    //         setComments(updatedComments);
    //     } else {
    //         setComments([...comments, newComments]);
    //     }
    //
    //     displayComments(projectId);
    // };
    var displayComments = function (projectId) {
        if (commentsToShow === projectId) {
            setCommentsToShow(null);
        }
        else {
            setCommentsToShow(projectId);
        }
    };
    var handleComment = function (e) {
        setEnteredComment(e.target.value);
    };
    React.useEffect(function () {
        fetchProjects();
    }, [langCode]);
    React.useEffect(function () {
        fetchProjects(searchInput !== searchedTerm ? searchInput : searchedTerm, page + 1);
    }, [searchedTerm]);
    React.useEffect(function () {
        if (projects)
            projects.forEach(function (p) { return fetchComments(p.id); });
    }, [projects]);
    return (_jsx(JesuisJechercheContext.Provider, __assign({ value: {
            projects: projects,
            projectsIsLoading: projectsIsLoading,
            page: page,
            pageCount: pageCount,
            nextProjects: nextProjects,
            view: view,
            setView: setView,
            count: count,
            searchedTerm: searchedTerm,
            searchInput: searchInput,
            comments: comments,
            commentEditing: commentEditing,
            commentIdToEdit: commentIdToEdit,
            enteredComment: enteredComment,
            commentsToShow: commentsToShow,
            getCurrentUserId: getCurrentUserId,
            confirmationModalIsOpen: confirmationModalIsOpen,
            setConfirmationModalIsOpen: setConfirmationModalIsOpen,
            fetchProjects: fetchProjects,
            loadNewProjects: loadNewProjects,
            getTitle: getTitle,
            handleKeyDown: handleKeyDown,
            searchProjects: searchProjects,
            handleSearchInput: handleSearchInput,
            fetchComments: fetchComments,
            createComment: createComment,
            deleteComment: deleteComment,
            editComment: editComment,
            resetCommentInputs: resetCommentInputs,
            //getComments,
            displayComments: displayComments,
            handleComment: handleComment,
            updateComment: updateComment,
        } }, { children: children })));
};
export var useJesuisJecherche = function () { return useContext(JesuisJechercheContext); };
export default JesuisJechercheContext;
