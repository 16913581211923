var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// eslint-disable-next-line no-unused-vars
import React from 'react';
// import { useTranslation } from "react-i18next";
import { ProjectHeader } from './ProjectHeader';
import { ProjectContent } from './ProjectContent';
import { ProjectMessageToggler } from './ProjectMessageToggler';
import { useJesuisJecherche } from "../../../../application/Context/JesuisJechercheContext";
import { Message } from '../Messages/Message';
import { MessageForm } from '../Messages/MessageForm';
export var Project = function (_a) {
    // const { t } = useTranslation(['public-room']);//verify project related translations
    var _b, _c;
    var project = _a.project;
    var _d = useJesuisJecherche(), commentsToShow = _d.commentsToShow, comments = _d.comments;
    var langCode = ((_b = document.getElementById("language-code")) === null || _b === void 0 ? void 0 : _b.value) || "en";
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    var f = Intl.DateTimeFormat(langCode, options);
    return (_jsxs("div", __assign({ id: project.id, "data-chanelid": project.id }, { children: [_jsx("div", __assign({ className: "custom-date-display" }, { children: _jsx("div", { children: f.format(new Date(project.date_creation)) }) })), _jsx("div", __assign({ className: 'post-unit' }, { children: _jsxs("div", __assign({ className: 'post-content-col' }, { children: [_jsx(ProjectHeader, { id: project.id, author: langCode === 'fr'
                                ? (project.entreprise.nom_legal_fr ? project.entreprise.nom_legal_fr : project.entreprise.nom_legal_en)
                                : (project.entreprise.nom_legal_en ? project.entreprise.nom_legal_en : project.entreprise.nom_legal_fr), date: project.date_creation }), _jsx(ProjectContent, { id: project.id, title: langCode === 'fr' ? project.nom_fr : project.nom_en, content: langCode === 'fr'
                                ? (project.description_fr !== "None" ? project.description_fr : project.description_en)
                                : (project.description_en !== "None" ? project.description_en : project.description_fr) }), _jsxs("div", __assign({ className: 'post-comments-section' }, { children: [_jsx(ProjectMessageToggler, { projectId: project.id }), commentsToShow === project.id && ((_c = comments[project.id]) === null || _c === void 0 ? void 0 : _c.map(function (msg) {
                                    return _jsx(Message, { id: msg.id, projectId: project.id, comment: msg.content, author: msg.author, date: msg.created_at }, msg.id);
                                })), commentsToShow === project.id &&
                                    _jsx(MessageForm, { projectId: project.id }, project.id)] }))] })) }))] })));
};
