var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// eslint-disable-next-line no-unused-vars
import React from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import { LoadingThread } from "../../shared-components/Loading/LoadingThread";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useJesuisJecherche } from "../../../../application/Context/JesuisJechercheContext";
import { Project } from "./Project";
var ProjectList = function () {
    var _a = useJesuisJecherche(), projects = _a.projects, hasMoreData = _a.hasMoreData, loadNewProjects = _a.loadNewProjects;
    var filteredProjects = projects.filter(function (project) { return project.visible_jesuis_jecherche; });
    var t = useTranslation(['jesuis-jecherche']).t; //don't know what is being used for jesuis jecherche
    return (_jsx(motion.div, __assign({ initial: { opacity: 0 }, animate: { opacity: 1 }, transition: { duration: 0.2 }, exit: { opacity: 0 } }, { children: _jsx("div", __assign({ className: "posts-section" }, { children: filteredProjects.length !== 0 ?
                _jsx(InfiniteScroll, __assign({ dataLength: filteredProjects.length, next: loadNewProjects, hasMore: hasMoreData, loader: _jsx(LoadingThread, {}), height: 80 + 'vh', endMessage: _jsx("p", __assign({ style: { textAlign: "center", marginTop: "14px" } }, { children: _jsxs("b", { children: [" ", t("Plus aucun projet")] }) })) }, { children: filteredProjects.map(function (project, i) {
                        return _jsx(Project, { project: project }, i);
                    }) }))
                :
                    _jsx("div", __assign({ className: "empty-message" }, { children: t("Veuillez vérifier le projet que vous cherchez !") })) })) })));
};
export default ProjectList;
