var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var SearchBarContainer = styled.fieldset(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  margin-bottom:10px;\n    margin-top: 42px;\n\n  &:disabled {\n    input {\n      opacity: 0.5;\n    }\n  }\n\n  input {\n    width: 89%;\n    border: 1px solid #808A9D;\n    padding: 7px;\n    color: ", ";\n  }\n\n   button {\n    background-color: ", ";\n    border: 0;\n    border-radius: 0;\n    padding: 8px;\n    width:40px;\n    height:40px;\n  }\n"], ["\n  width: 100%;\n  display: flex;\n  margin-bottom:10px;\n    margin-top: 42px;\n\n  &:disabled {\n    input {\n      opacity: 0.5;\n    }\n  }\n\n  input {\n    width: 89%;\n    border: 1px solid #808A9D;\n    padding: 7px;\n    color: ", ";\n  }\n\n   button {\n    background-color: ", ";\n    border: 0;\n    border-radius: 0;\n    padding: 8px;\n    width:40px;\n    height:40px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.blueFonce;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.bluePiv;
});
var templateObject_1;
