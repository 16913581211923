var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from 'react';
import { BackDrop, ModalStyle } from './ModalStyles';
import ReactDOM from 'react-dom';
import JesuisJechercheContext from '../../../../application/Context/JesuisJechercheContext';
var Backdrop = function () {
    var handleHideThreadForm = useContext(JesuisJechercheContext).handleHideThreadForm;
    return _jsx(BackDrop, __assign({ onClick: handleHideThreadForm }, { children: " " }));
};
var ModalOverlay = function (_a) {
    var children = _a.children;
    return (_jsx("div", { children: _jsxs(ModalStyle, { children: [" ", children, " "] }) }));
};
export var Modal = function (_a) {
    var children = _a.children;
    var portalElemnt = document.getElementById('overlays');
    return (_jsxs(React.Fragment, { children: [ReactDOM.createPortal(_jsx(Backdrop, {}), portalElemnt), ReactDOM.createPortal(_jsxs(ModalOverlay, { children: [" ", children, " "] }), portalElemnt)] }));
};
