var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import Linkify from 'react-linkify';
export var MessageContent = function (_a) {
    var message = _a.message;
    var componentDecorator = function (href, text, key) { return (_jsx("a", __assign({ className: "linkify__text", rel: "noreferrer", href: href, target: "_blank" }, { children: text }), key)); };
    return (_jsx(React.Fragment, { children: _jsx("div", __assign({ className: "post-comments-unit-cont" }, { children: _jsxs("div", { children: [" ", _jsx(Linkify, __assign({ componentDecorator: componentDecorator }, { children: message })), " "] }) })) }));
};
