var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef } from 'react';
// eslint-disable-next-line no-unused-vars
import { ActionsTooltipMessage } from '../Actions/ActionsTooltipMessage';
import ReactTimeAgo from 'react-time-ago';
import useOutsideClick from "../../shared-components/useOutsideClick";
import { useJesuisJecherche } from "../../../../application/Context/JesuisJechercheContext";
export var MessageHeader = function (_a) {
    var id = _a.id, author = _a.author, date = _a.date, projectId = _a.projectId;
    var getCurrentUserId = useJesuisJecherche().getCurrentUserId;
    var companyName = author.company.name;
    var capitalizedAuthorName = companyName.charAt(0).toUpperCase() + companyName.slice(1);
    var initials = capitalizedAuthorName.charAt(0);
    var isAuthor = parseInt(getCurrentUserId) === author.id;
    // Close tooltip when user click anywhere
    var tooltipRef = useRef(null);
    useOutsideClick(tooltipRef, function () {
        setControllersTooltip("");
    });
    var _b = __read(React.useState(''), 2), controllersTooltip = _b[0], setControllersTooltip = _b[1];
    var handleControllersTooltip = function (e) {
        var eleId = e.target.getAttribute('data-id');
        if (controllersTooltip === eleId) {
            setControllersTooltip('');
        }
        else {
            setControllersTooltip(eleId);
        }
    };
    return (_jsxs(React.Fragment, { children: [_jsxs("div", __assign({ className: "post-comment-unit-header" }, { children: [_jsxs("div", __assign({ className: 'post-header-unit-top' }, { children: [_jsx("div", __assign({ className: 'post-avatar-col' }, { children: _jsx("div", __assign({ className: 'post-avatar' }, { children: initials })) })), _jsx("div", { children: _jsx("p", __assign({ className: 'comment-auth-name' }, { children: capitalizedAuthorName })) })] })), _jsx("div", __assign({ className: 'post-comment-controllers', ref: tooltipRef }, { children: _jsxs("div", __assign({ className: 'post-controllers' }, { children: [controllersTooltip === "tooltip-".concat(id) && isAuthor && (_jsx(ActionsTooltipMessage, { messageId: id, type: "message", projectId: projectId })), _jsx("div", __assign({ className: 'post-controllers-container' }, { children: isAuthor && _jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/vertical-dots.svg"), alt: "dots", "data-id": "tooltip-".concat(id), onClick: function (e) { return handleControllersTooltip(e); } }) }))] })) }))] })), _jsx("p", __assign({ className: 'time-ago' }, { children: _jsx(ReactTimeAgo, { date: new Date(date), locale: "en-us", timeStyle: "twitter" }) }))] }));
};
