var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// eslint-disable-next-line no-unused-vars
import React, { CSSProperties } from 'react';
import { useTranslation } from "react-i18next";
import { useJesuisJecherche } from '../../../../application/Context/JesuisJechercheContext';
import { ConfirmationModal } from '../ConfirmationModal/ConfirmationModal';
export var ActionsTooltipMessage = function (_a) {
    var messageId = _a.messageId, type = _a.type, projectId = _a.projectId;
    var t = useTranslation(["common"]).t;
    var _b = useJesuisJecherche(), setConfirmationModalIsOpen = _b.setConfirmationModalIsOpen, editComment = _b.editComment;
    // eslint-disable-next-line no-unused-vars
    var tooltipStyle = {
        'backgroundColor': 'white',
        'width': '200px',
        'height': '40px',
        'position': 'absolute',
        'marginLeft': '-160px',
        'marginTop': '-50px',
        'boxShadow': 'rgba(99, 99, 99, 0.2) 0 2px 9px 2px'
    };
    return (_jsxs(React.Fragment, { children: [_jsx(ConfirmationModal, { projectId: projectId, messageId: messageId, type: type }), _jsx("div", __assign({ className: "post-comment-controllers-tooltip" }, { children: _jsxs("div", __assign({ className: 'buttons-wrapper' }, { children: [_jsxs("button", __assign({ onClick: function () { return setConfirmationModalIsOpen(true); }, style: { 'backgroundColor': 'transparent', 'border': 'none', 'padding': 8 } }, { children: [_jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/trash-blue-fonce.svg"), alt: "delete" }), t("Delete")] })), _jsxs("button", __assign({ onClick: function (e) { return editComment(e, projectId, messageId); }, style: { 'backgroundColor': 'transparent', 'border': 'none', 'padding': 8 } }, { children: [_jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/pencil-blue-fonce.svg"), alt: "edite" }), t("Edit")] }))] })) }))] }));
};
