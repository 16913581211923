var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useJesuisJecherche } from '../../../../application/Context/JesuisJechercheContext';
import { useTranslation } from "react-i18next";
export var ProjectMessageToggler = function (_a) {
    var projectId = _a.projectId;
    var t = useTranslation(['public-room']).t; //verify project related translations
    var _b = useJesuisJecherche(), commentsToShow = _b.commentsToShow, comments = _b.comments, displayComments = _b.displayComments; // add comments in destruct
    var responseCount = comments[projectId] ? comments[projectId].length : 0;
    return (_jsxs(React.Fragment, { children: [_jsxs("div", __assign({ className: "post-comment-header", id: "msg-".concat(projectId), onClick: function () { return displayComments(projectId); } }, { children: [responseCount, " ", t("réponse/s"), commentsToShow === projectId ? (_jsxs("div", { children: [_jsxs("span", __assign({ className: 'me-1' }, { children: [" ", t("Réduire tous"), "  "] })), _jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/arrow-up-blue.svg"), alt: "hide all" })] })) : (_jsxs("div", { children: [_jsxs("span", __assign({ className: 'me-1' }, { children: [t("Afficher tous"), " "] })), _jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/arrow-down-blue.svg"), alt: "show all" })] }))] })), commentsToShow !== projectId ? (_jsx("div", __assign({ className: 'post-comment-footer', onClick: function () { return displayComments(projectId); } }, { children: _jsxs("div", __assign({ className: 'post-comment-footer' }, { children: [_jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/comment.svg"), alt: "comment" }), _jsx("div", { children: t("Commentaires") })] })) }))) : ""] }));
};
